import React, {useEffect} from "react";
import {Button, Checkbox, Col, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {
	hideMessage,
	userSignIn,
	userSignInByKey,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import InfoView from "../components/InfoView";

const SignInRemote = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const authUser = useSelector(({auth}) => auth.authUser);
	
	// const onFinishFailed = errorInfo => {
	// 	console.log('Failed:', errorInfo);
	// };
	
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let key = urlParams.get('key');
	
	useEffect(() => {
		dispatch(userSignInByKey(key));
	}, []);
	
	useEffect(() => {
		if (authUser !== null) {
			history.push('/');
		}
	}, [authUser]);
	
	
	return (
		<></>
	);
};

export default SignInRemote;
