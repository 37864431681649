import {GET_ALL_SERVICE, GET_SERVICE_DETAILS, CREATE_SERVICE, UPDATE_SERVICE, DELETE_SERVICE} from "../../constants/ActionTypes";

const INIT_STATE = {
  services: [],
  service: [],
  status: 0
};

export default (state = INIT_STATE, action) => {
  // const { type, payload } = action;
  switch (action.type) {

    case GET_ALL_SERVICE: {
      return {
        ...state,
        services: action.payload,
        status: 0
      };
    }

    case CREATE_SERVICE:
      return {
        ...state,
        // services: [action.payload, ...state.services],
        status: 1
      }

    case GET_SERVICE_DETAILS: {
      // console.log(state.services)
      return {
        ...state,
        service: action.payload,
        status: 0
      };
    }

    case UPDATE_SERVICE:
      // console.log(action.payload)
      // console.log(state)
      // console.log(state.services)
      return {
        ...state,
        status: 1,
        services: state.services.map((item, i) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload.values,
            };
          } else {
            return item;
          }
        })
      }
      // console.log(state.service)

    case DELETE_SERVICE: {
      return {
        ...state,
        services: state.services.filter(({id}) => id !== action.payload.id )
      }
    }

    default:
      return state;
  }
}
