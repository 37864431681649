// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const UPDATE_LOAD_USER = 'UPDATE_LOAD_USER';
export const USER_DATA = 'USER_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

// Country
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY';

// Customer
export const GET_ALL_CUSTOMER = 'GET_ALL_CUSTOMER';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';


// Employee
export const GET_ALL_EMPLOYEE = 'GET_ALL_EMPLOYEE';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const GET_EMPLOYEE_DETAILS = 'GET_EMPLOYEE_DETAILS';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

// Service
export const GET_ALL_SERVICE = 'GET_ALL_SERVICE';
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const GET_SERVICE_DETAILS = 'GET_SERVICE_DETAILS';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';

