import React, { useEffect, useState } from "react";
import {Breadcrumb, Col, Input, Layout, Row, Select, Form, notification} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";

const { Option } = Select;
const { TextArea } = Input;
const EditTicket = () => {
	const getuserdata = CisUI().getLocalUserData();
	const profileID = JSON.parse(getuserdata).id;

	const [profile, setProfile] = useState([]);
    const [ticket, serTicket] = useState("");
	const [image, setImage] = useState("");

	const [services, setServices] = useState([]);

	const getServices = () => {
		axios
			.get(config.apiserver + "service/servicelist", CisUI().HeaderRequest)
			.then((res) => setServices(res.data.data))
			.catch((errors) => console.log(errors));
	};
	useEffect(() => {
		getServices();
	}, []);

	const history = useHistory();
	const changeImage = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.onload = (e) => {
			setImage({
				image: e.target.result,
			});
		};
		reader.readAsDataURL(file);
	};

	const onFinish = (values) => {
		console.log(values);

		const data = {
			...values,
			customer_id: profileID,
		};

		axios.post(
				config.apiserver + `customersupport/customersupportupdate/${ticket.id}`,
				data,
				CisUI().HeaderRequest
			)
			.then((res) => {
				if (res.data.status == 1) {
					console.log("Making Request");

					notification.success({
						message: "Alert",
						type: "warning",
						description: res.data.msg,
					});
					history.push("./ticketlist");
				} else {
					console.log(res.data.msg);
					notification.warning({
						message: "warning",
						type: "error",
						description: res.data.msg,
					});
				}
			})
			.catch((errors) => console.log(errors));
	};

	const getProfileData = () => {
		axios
			.get(
				config.apiserver + `settings/showprofile/${profileID}`,
				CisUI().HeaderRequest
			)
			.then((res) => {
				if (res.data.status == 1) {
					setProfile(res.data.data);
					console.log(res.data.data);
				} else {
					console.log("error");
				}
			})
			.catch((errors) => console.log(errors));
	};

	const getTicketData = () => {
		axios
			.get(
				config.apiserver + `customersupport/customersupportedit/${profileID}`,
				CisUI().HeaderRequest
			)
			.then((res) => {
				if (res.data.status == 1) {
                    serTicket(res.data.data)
					console.log(res.data.data);
				} else {
					console.log("error");
				}
			})
			.catch((errors) => console.log(errors));
	};
	useEffect(() => {
		getProfileData();
        getTicketData();
	}, []);

	return (
		<main className="ant-layout-content gx-layout-content gx-container-wrap ">
			<div className="home-main-content">
				<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
					<div className="gx-algolia-main">
						<h1 className="home-top-header">Edit Ticket</h1>
						<Breadcrumb className="home-top-breadcrumb">
							<Breadcrumb.Item>
								<Link to="/">Portal Home</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item>Submit Ticket</Breadcrumb.Item>
						</Breadcrumb>
						<Content className="gx-algolia-content">
							<div className="gx-algolia-content-inner">
								{profile && ticket != "" ? (
                                    	<Form
										onFinish={onFinish}
										initialValues={{
											name: profile.name,
											email: profile.email,
                                            subject: ticket.subject,
                                            area:ticket.department,
                                            department:ticket.department,
                                            service_id:ticket.service_id,
                                            priority:ticket.priority,
                                            details:ticket.details,
                                            file:ticket.file
										}}
									>
										<Row
											className="ant-row"
											style={{ rowGap: "15px" }}
											justify="space-between"
										>
											<Col lg={12} xs={24}>
												<Form.Item
													label="Name"
													rules={[
														{ required: true, message: "Name is required" },
													]}
													name="name"
													className="form-lblock"
												>
													<Input placeholder="Name" disabled={true} />
												</Form.Item>
											</Col>
											<Col lg={12} xs={24}>
												<Form.Item
													label="Email"
													rules={[
														{ required: true, message: "Email is required" },
													]}
													name="email"
													className="form-lblock"
												>
													<Input placeholder="Email" disabled={true} />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Subject"
													rules={[
														{ required: true, message: "Subject is required" },
													]}
													name="subject"
													className="form-lblock"
												>
													<Input placeholder="Subject" />
												</Form.Item>
											</Col>
											<Col lg={12} xs={24}>
												<Form.Item
													label="Area"
													rules={[
														{ required: true, message: "Area is required" },
													]}
													name="area"
													className="form-lblock"
												>
													<Select
														placeholder="Please select your Area"
														style={{ width: "100%" }}
													>
														<Option value="bd">Bangladesh</Option>
														<Option value="usa">U.S.A</Option>
														<Option value="ghana">Ghana</Option>
													</Select>
												</Form.Item>
											</Col>
											<Col lg={8} xs={24}>
												<Form.Item
													label="Department"
													rules={[
														{
															required: true,
															message: "Department is required",
														},
													]}
													name="department"
													className="form-lblock"
												>
													<Select
														placeholder="Please select your department"
														style={{ width: "100%" }}
													>
														<Option value="technical-support">
															Technical Support
														</Option>
													</Select>
												</Form.Item>
											</Col>
											<Col lg={8} xs={24}>
												<Form.Item
													label="Related Service"
													rules={[
														{
															required: true,
															message: "Related Service is required",
														},
													]}
													name="service_id"
													className="form-lblock"
												>
													<Select
														placeholder="Please select your service"
														style={{ width: "100%" }}
													>
														{services.map((service, index) => (
															<Option key={++index} value={service.id}>
																{service.name}
															</Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col lg={8} xs={24}>
												<Form.Item
													label="Priority"
													rules={[
														{
															required: true,
															message: "Priority is required",
														},
													]}
													name="priority"
													className="form-lblock"
												>
													<Select
														placeholder="Please select priority"
														style={{ width: "100%" }}
													>
														<Option value="High">High</Option>
														<Option value="Medium">Medium</Option>
														<Option value="Low">Low</Option>
													</Select>
												</Form.Item>
											</Col>
											<Col lg={24} xs={24}>
												<Form.Item
													label="Message"
													rules={[
														{
															required: true,
															message: "Message is required",
														},
													]}
													name="details"
													className="form-lblock"
												>
													<TextArea rows={5} />
												</Form.Item>
											</Col>
											<Col lg={24} xs={24}>
												<Form.Item
													label="File Attachment"
													name="file"
													className="form-lblock"
												>
													<input
														type="file"
														name="file"
														onChange={changeImage}
													/>
												</Form.Item>
											</Col>
											<Col lg={24} xs={24} style={{ textAlign: "center" }}>
												<Button type="secondary" htmlType="reset">
													Reset
												</Button>
												<Button type="primary" htmlType="submit">
													Submit
												</Button>
											</Col>
										</Row>
									</Form>
							
								) : (
                                    <Loading />
								)}
							</div>
						</Content>
					</div>
				</Layout>
			</div>
		</main>
	);
};

export default EditTicket;

