import React, { useState, useEffect } from "react";
import { Breadcrumb, Col, Input, Layout, Row, Form, notification, Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
const { TextArea } = Input;

const SoftwareSettings = () => {
	const history = useHistory();
	const [software, setSoftware] = useState([]);
	const onFinish = (values) => {
		console.log(values);

		axios
			.post(
				config.apiserver + `settings/updatesystemsettingdata/1`,
				values,
				CisUI().HeaderRequest
			)
			.then((res) => {
				if (res.data.status === 1) {
					// console.log("Making Request");
					notification.success({
						message: 'Update Software Settings',
						description: res.data.msg
					});
					history.push("./software_settings");
				} else {
					// console.log("error");
					// console.log("Making Request Error");
					notification.warning({
						message: 'Something is Wrong',
						description: res.data.msg
					});
					history.push("./software_settings");
				}
			})
			.catch((errors) => {
				notification.error({
					message: 'Error',
					description: "Software Settings not update"
				});
				console.log(errors)});
	};

	const getSoftwareSettings = () => {
		axios
			.get(
				config.apiserver + "settings/showsystemsettingdata/1",
				CisUI().HeaderRequest
			)
			.then((res) => {
				if (res.data.status === 1) {
					setSoftware(res.data.data);
					console.log(res.data.data);
				} else {
					console.log("error");
				}
			})
			.catch((errors) => console.log(errors));
	};

	useEffect(() => {
		getSoftwareSettings();
	}, []);

	return (
		<main className="ant-layout-content gx-layout-content gx-container-wrap ">
			<div className="home-main-content">
				<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
					<div className="gx-algolia-main">
						<h1 className="home-top-header">Software Settings</h1>
						<Breadcrumb className="home-top-breadcrumb">
							<Breadcrumb.Item>
								<Link to="/">Portal Home</Link>
							</Breadcrumb.Item>

							<Breadcrumb.Item>Software Settings</Breadcrumb.Item>
						</Breadcrumb>
						<Content className="gx-algolia-content">
							<div className="gx-algolia-content-inner">
								{software == "" ? (
									<Loading />
								) : (
									<Form
										onFinish={onFinish}
										initialValues={{
											title: software.title,
											email: software.email,
											website: software.website,
											phone: software.phone,
											address: software.address,
											details: software.details,
										}}
									>
										<Row
											className="ant-row"
											style={{ rowGap: "15px" }}
											justify="space-between"
										>
											<Col lg={12} xs={24}>
												<Form.Item
													label="Title"
													rules={[
														{ required: true, message: "Title is required" },
													]}
													name="title"
													className="form-lblock"
												>
													<Input placeholder="Title" />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Email"
													rules={[
														{ required: true, message: "Email is required" },
													]}
													name="email"
													className="form-lblock"
												>
													<Input placeholder="Email" />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Website"
													rules={[
														{ required: true, message: "Website is required" },
													]}
													name="website"
													className="form-lblock"
												>
													<Input placeholder="Website" />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Phone"
													rules={[
														{ required: true, message: "Phone is required" },
													]}
													name="phone"
													className="form-lblock"
												>
													<Input placeholder="Phone" />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Address"
													rules={[
														{ required: true, message: "Address is required" },
													]}
													name="address"
													className="form-lblock"
												>
													{/* <Input placeholder="Address" /> */}
													<TextArea rows={5} placeholder="Address" />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Details"
													rules={[
														{ required: true, message: "Details is required" },
													]}
													name="details"
													className="form-lblock"
												>
													{/* <Input placeholder="Details" /> */}
													<TextArea rows={5} placeholder="Details" />
												</Form.Item>
											</Col>

											<Col lg={24} xs={24} style={{ textAlign: "center" }}>
												<Button type="secondary" htmlType="reset">
													Reset
												</Button>
												<Button type="primary" htmlType="submit">
													Change Software Settings
												</Button>
											</Col>
										</Row>
									</Form>
								)}
							</div>
						</Content>
					</div>
				</Layout>
			</div>
		</main>
	);
};

export default SoftwareSettings;
