import React, { useEffect } from "react";
import { Breadcrumb, Col, Input, Layout, Row, Select, Form, Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone, MenuOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../appRedux/actions/Country";
import { addCustomer } from "../../appRedux/actions/Customer";

const { Option } = Select;

const AddCustomer = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	let countries = useSelector(({ country }) => country.countries);
	let createStatus = useSelector(({ customer }) => customer.status)
	// console.log(createStatus);

	useEffect(() => {
		dispatch(getCountry());
	}, []);

	const onFinish = (values) => {
		dispatch(addCustomer(values))
	};

	if (createStatus === 1) {
		history.push("/admin/customer/customerlist");
	}

	return (
		<main className="ant-layout-content gx-layout-content gx-container-wrap ">
			<div className="home-main-content">
				<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
					<div className="gx-algolia-main">
						<h1 className="home-top-header">Add Customer
							<Link to="./customerlist"><Button className="title-btn" icon={<MenuOutlined />}/></Link>
							
							
						</h1>
						<Breadcrumb className="home-top-breadcrumb">
							<Breadcrumb.Item>
								<Link to="/">Portal Home</Link>
							</Breadcrumb.Item>

							<Breadcrumb.Item>Add Customer</Breadcrumb.Item>
						</Breadcrumb>
						<Content className="gx-algolia-content">
							<div className="gx-algolia-content-inner">
								<Form onFinish={onFinish}>
									<Row
										className="ant-row"
										style={{ rowGap: "15px" }}
										justify="space-between"
									>
										<Col lg={12} xs={24}>
											<Form.Item
												label="Username"
												rules={[
													{ required: true, message: "Username is required" },
												]}
												name="user_name"
												className="form-lblock"
											>
												<Input placeholder="Username" />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="Name"
												rules={[
													{ required: true, message: "Name is required" },
												]}
												name="name"
												className="form-lblock"
											>
												<Input placeholder="Name" />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="Phone"
												rules={[
													{ required: true, message: "Phone is required" },
												]}
												name="phone"
												className="form-lblock"
											>
												<Input placeholder="Phone" />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="Email"
												rules={[
													{ required: true, message: "Email is required" },
												]}
												name="email"
												className="form-lblock"
											>
												<Input placeholder="Email" />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="Address"
												rules={[
													{ required: true, message: "Address is required" },
												]}
												name="address"
												className="form-lblock"
											>
												<Input placeholder="Email" />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="Password"
												rules={[
													{ required: true, message: "Password is required" },
												]}
												name="password"
												className="form-lblock"
											>
												<Input.Password placeholder="Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="City"
												rules={[
													{ required: true, message: "City is required" },
												]}
												name="city"
												className="form-lblock"
											>
												<Input placeholder="City" />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="State"
												rules={[
													{ required: true, message: "State is required" },
												]}
												name="state"
												className="form-lblock"
											>
												<Input placeholder="State" />
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="Country"
												rules={[
													{ required: true, message: "Country is required" },
												]}
												name="country"
												className="form-lblock"
											>
												<Select
													showSearch
													// optionFilterProp="children"
													placeholder="Select Your Country"
													style={{ width: "100%" }}
													filterOption={(input, option) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												// name="country"
												>
													{countries.map((country, index) => (
														<Option key={++index} value={country.id}>
															{country.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>
											<Form.Item
												label="Zip Code"
												rules={[
													{ required: true, message: "Zip Code is required" },
												]}
												name="zip_code"
												className="form-lblock"
											>
												<Input placeholder="State" />
											</Form.Item>
										</Col>

										<Col lg={24} xs={24}>
											<Form.Item
												label="User Status"
												rules={[
													{
														required: true,
														message: "User Status is required",
													},
												]}
												name="status"
												className="form-lblock"
											>
												<Select
													placeholder="Select An Option"
													style={{ width: "100%" }}
													name="status"
												>
													<Option value="1">Active</Option>
													<Option value="0">Inactive</Option>
												</Select>
											</Form.Item>
										</Col>

										<Col lg={24} xs={24} style={{ textAlign: "center" }}>
											<Button type="secondary" htmlType="reset">
												Reset
											</Button>
											<Button type="primary" htmlType="submit">
												Save
											</Button>
										</Col>
									</Row>
								</Form>
							</div>
						</Content>
					</div>
				</Layout>
			</div>
		</main>
	);
};

export default AddCustomer;
