import React from "react";
import { Breadcrumb, Col, Input, Layout, Row, Form, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";

import { Button } from "antd";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const ChangePassword = () => {
	const getuserdata = CisUI().getLocalUserData();
	const profileID = JSON.parse(getuserdata).id;

	const history = useHistory();

	const onFinish = (values) => {
		console.log(values);

		axios.post(config.apiserver + `settings/updatepassword/${profileID}`, values, CisUI().HeaderRequest)
			.then((res) => {
				if (res.data.status == 1) {
					notification.success({
						message: 'Change Password',
						description: res.data.msg
					});
					history.push("./change_password");
				} else {
					notification.warning({
						message: 'Something is Wrong',
						description: res.data.msg
					});
					history.push("./change_password");
				}
			})
			.catch((errors) => {
				console.log(errors);
				
				// notification.error({
				// 	message: 'Error',
				// 	description: "Password not change"
				// });
			});
	};

	return (
		<main className="ant-layout-content gx-layout-content gx-container-wrap ">
			<div className="home-main-content">
				<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
					<div className="gx-algolia-main">
						<h1 className="home-top-header">Change Password</h1>
						<Breadcrumb className="home-top-breadcrumb">
							<Breadcrumb.Item>
								<Link to="/">Portal Home</Link>
							</Breadcrumb.Item>

							<Breadcrumb.Item>Change Password</Breadcrumb.Item>
						</Breadcrumb>
						<Content className="gx-algolia-content">
							<div className="gx-algolia-content-inner">
								
								<Form onFinish={onFinish}>
									<Row
										className="ant-row"
										style={{ rowGap: "15px" }}
										justify="space-between"
									>
										<Col lg={12} xs={24}>
											<Form.Item
												label="Old Password"
												rules={[
													{ required: true, message: "Password is required" },
												]}
												name="old_password"
												className="form-lblock"
											>
												<Input.Password
													placeholder="Old Password"
													iconRender={(visible) =>
														visible ? (
															<EyeTwoTone />
														) : (
															<EyeInvisibleOutlined />
														)
													}
												/>
											</Form.Item>
										</Col>

										<Col lg={12} xs={24}>

											<Form.Item
												name="password"
												label="Password"
												className="form-lblock"
												rules={[
													{
														required: true,
														message: "Please input your password!",
													},
												]}
												hasFeedback
											>
												<Input.Password placeholder="Password"/>
											</Form.Item>
										</Col>
										<Col lg={12} xs={24}>


											<Form.Item
												name="confirm"
												label="Confirm Password"
												dependencies={["password"]}
												hasFeedback
												className="form-lblock"
												rules={[
													{
														required: true,
														message: "Please confirm your password!",
													},
													({ getFieldValue }) => ({
														validator(_, value) {
															if (
																!value ||
																getFieldValue("password") === value
															) {
																return Promise.resolve();
															}
															return Promise.reject(
																new Error(
																	"The two passwords that you entered do not match!"
																)
															);
														},
													}),
												]}
											>
												<Input.Password placeholder="Confirm Password"/>
											</Form.Item>
										</Col>

										<Col lg={24} xs={24} style={{ textAlign: "center" }}>
											<Button type="secondary" htmlType="reset">
												Reset
											</Button>
											<Button type="primary" htmlType="submit">
												Change Password
											</Button>
										</Col>
									</Row>
								</Form>
								
							</div>
						</Content>
					</div>
				</Layout>
			</div>
		</main>
	);
};

export default ChangePassword;
