import React from "react";

const Loading = () => {
  return (
    <>
      <img
        src="/assets/images/cis_loading.gif"
        alt="Loading"
        style={{ width: "10%", margin: "auto", display: "block" }}
        id="loading"
      />
    </>
  );
};

export default Loading;
