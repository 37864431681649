import {GET_ALL_COUNTRY} from "../../constants/ActionTypes";

const INIT_STATE = {
  countries: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL_COUNTRY: {
      return {...state, countries: action.payload};
    }

    default:
      return state;
  }
}
