import React from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Service from "./service";
import Customer from "./customer";
import Account from "./account";
import Billing from "./billing";
import Settings from "./settings";
import Employee from "./employee";
import Support from "./support";

const App = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
        <Route path={`${match.url}/service`} component={Service} />
        <Route path={`${match.url}/customer`} component={Customer} />
        <Route path={`${match.url}/account`} component={Account} />
        <Route path={`${match.url}/billing`} component={Billing} />
        <Route path={`${match.url}/settings`} component={Settings} />
        <Route path={`${match.url}/employee`} component={Employee} />
        <Route path={`${match.url}/support`} component={Support} />
    </Switch>
);

export default App;
