import React from "react";
import { Route, Switch } from "react-router-dom";

import OpenTicket from "../../../containers/Support/OpenTicket";
import TicketList from "../../../containers/Support/TicketList";
import ViewTicket from "../../../containers/Support/ViewTicket";
import EditTicket from "../../../containers/Support/EditTicket";

const Support = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/ticketlist`} component={TicketList} />
      <Route path={`${match.url}/open-ticket`} component={OpenTicket} />
      <Route path={`${match.url}/edit-ticket/:id`} component={EditTicket} />
      <Route path={`${match.url}/view-ticket/:id`} component={ViewTicket} />
    </Switch>
  </div>
);

export default Support;
