import React from "react";
import { Route, Switch } from "react-router-dom";
import Update from "../../../containers/Accounts/Update";



const Account = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/update`} component={Update}/>

    </Switch>
  </div>
);

export default Account;
