import {GET_ALL_CUSTOMER, GET_CUSTOMER_DETAILS, CREATE_CUSTOMER, UPDATE_CUSTOMER, DELETE_CUSTOMER} from "../../constants/ActionTypes";

const INIT_STATE = {
  customers: [],
  customer: [],
  status: 0,
};

export default (state = INIT_STATE, action) => {
  const { type, payload} = action;

  switch (type) {

    case GET_ALL_CUSTOMER:
      return {
        ...state,
        customers: payload,
        status: state.status = 0
      };


    case CREATE_CUSTOMER:
      return {
        ...state,
        status: state.status = 1
      }

    case GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customer: payload,
        status: state.status = 0
      }

    case UPDATE_CUSTOMER:
      return {
        ...state,
        status: state.status = 1,
        // customers: state.customers.map((item, i) =>
        //     // console.log(parseInt(payload.id))
        //   item.id === parseInt(payload.id) ? {...item, customer: payload.values} : item
        // )
      }

    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(({id}) => id !== payload.id)
      }

    default:
      return state;
  }
}
