import React, { useState, useEffect } from "react";
import { Breadcrumb, Input, Layout, Card, Select, Avatar, Button, Collapse, Col, Row, Form, notification, Tag } from "antd";
import { Link } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import CKEditor from "react-ckeditor-component";
import moment from 'moment';
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
const { Meta } = Card;
const { Panel } = Collapse;

const ViewTicket = (props) => {

    const marked = window.marked
    const [ckeEdit, setckeEdit] = useState("")
    const [ticket, setTicket] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(0);
    const [form] = Form.useForm();
    let userData = useSelector((state) => state.auth.profileData);
    // console.log(userData);
    let id = props.match.params.id;
    const getTicket = () => {
        setLoading(true)
        axios.get(config.apiserver + "customersupport/customersupportedit/" + id, CisUI().HeaderRequest)
            .then((res) => {
                // console.log(res.data.data);
                setTicket(res.data.data)
                setLoading(false)
            })
            .catch((errors) => {
                setLoading(false)
            });
    };

    useEffect(() => {
        getTicket()
    }, [status])

    const onFinish = (values) => {
        // console.log(values);
        setLoading(true)

        const data = {
            // ...values,
            body: ckeEdit,
            support_id: id,
            subject: ticket.subject
        };

        // console.log(data);

        axios.post(config.apiserver + `reply/newreply`, data, CisUI().HeaderRequest)
            .then((res) => {
                if (res.data.status == 1) {
                    setLoading(false)
                    notification.success({
                        message: "Success",
                        type: "success",
                        description: res.data.msg,
                    });
                    // history.push("./ticketlist");
                    setFlag(!flag)
                    form.resetFields();
                    setckeEdit("")
                } else {
                    // console.log(res.data.msg);
                    setLoading(false)
                    notification.warning({
                        message: "warning",
                        type: "error",
                        description: res.data.msg,
                    });
                }
            }).catch((errors) => {
                setLoading(false)
                // console.log(errors)
                notification.error({
                    message: "Error",
                    type: "error",
                    description: "Something is Wrong",
                });
            });
    };

    const getTickets = () => {
        setLoading(true)
        axios.get(config.apiserver + "reply/replylist/" + id, CisUI().HeaderRequest)
            .then((res) => {
                setLoading(false)
                console.log("tickets");
                console.log(res.data.data);
                setTickets(res.data.data)
            })
            .catch((errors) => {
                setLoading(false)
            });
    };

    useEffect(() => {
        getTickets()
    }, [flag])

    const genExtra = () => (
        <EditOutlined />
    );

    const changeStatus = () => {
        setLoading(true)
        axios.post(config.apiserver + "customersupport/changestatus/" + id, CisUI().HeaderRequest)
            .then((res) => {
                // console.log(res.data.data);
                if (res.data.status === 1) {
                    setLoading(false)
                    notification.success({
                        message: "Success",
                        type: "success",
                        description: res.data.msg,
                    });
                    setStatus(1)
                }
                else {
                    setLoading(false)
                    notification.warning({
                        message: "warning",
                        type: "error",
                        description: res.data.msg,
                    });
                }
            })
            .catch((errors) => {
                setLoading(false)
            });
    }


    const handleChange = (evt) => {
        var newContent = evt.editor.getData();
        setckeEdit(newContent)
    }


    const createDate = (orgianlDate) => {
        var date = new Date(orgianlDate);
        console.log(moment().format(orgianlDate));
        date = date.toDateString() + " " + date.toLocaleTimeString('en-US');
        // toString
        return date;
    }
    return (
        loading ? <Loading/> : (
            <main className="ant-layout-content gx-layout-content gx-container-wrap ">
                <div className="home-main-content">
                    <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                        <div className="gx-algolia-main">
                            <h1 className="home-top-header">Ticket {ticket?.ticket_no} - {ticket?.subject}</h1>
                            <Breadcrumb className="home-top-breadcrumb">
                                <Breadcrumb.Item>
                                    <Link to="/">Portal Home</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Support Ticket</Breadcrumb.Item>
                                <Breadcrumb.Item>Ticket - {ticket?.ticket_no}</Breadcrumb.Item>
                            </Breadcrumb>

                            <Content>
                                <Card bordered={false}>
                                    {ticket.status === 1 ? <Button className="close-btn" danger> Closed </Button> : <Button className="close-btn" type="primary" onClick={changeStatus}>Close</Button>}
                                    <Meta avatar={userData.image != null &&
                                        <Avatar
                                            src={config.profile_img + userData.image}
                                            className="gx-pointer"
                                            alt=""
                                            style={{ width: "40px", height: "40px" }}

                                        />
                                    }
                                        title={`${userData.name}`}
                                        description={userData.email}
                                    />

                                    {
                                        ticket?.file && (
                                            <a href={`${config.ticket_file}` + ticket?.file} target="_blank">{ticket?.file}</a>
                                        )
                                    }

                                    <Card type="inner" bordered={false} className="view-ticket-details">
                                        {ticket && ticket?.details &&
                                            <div dangerouslySetInnerHTML={{ __html: marked.parse(ticket?.details) }} />
                                        }

                                    </Card>
                                </Card>
                            </Content>
                            <Collapse style={{ marginBottom: "20px" }}>
                                <Panel showArrow={false} header="Reply" key="1" extra={genExtra()}>
                                    <Form onFinish={onFinish} form={form}>
                                        <Row className="ant-row">

                                            <Col lg={24} xs={24}>
                                                <Form.Item label="Message" className="form-lblock">
                                                    <CKEditor
                                                        activeClass="p10"
                                                        content={ckeEdit}
                                                        events={{
                                                            "change": handleChange
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={24} xs={24} style={{ textAlign: "center", marginTop: "20px" }}>
                                                <Button type="secondary" htmlType="reset">
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Panel>
                            </Collapse>
                            {/* )} */}
                            {tickets.map((reply, index) =>

                                <Card key={index} size="small" bordered={false} className="reply-list" title={`Posted By ${reply.user_id === 1 ? "Creative It Soft" : userData.name} on ${createDate(reply.created_at)}`} extra={reply.user_id === 1 ? <Tag color="#108ee9">Operator</Tag> : <Tag color="#87d068">Owner</Tag>}>
                                    {/* {
                                        ticket?.file && (
                                            <a href={`${config.ticket_file}` + ticket?.file} target="_blank">{ticket?.file}</a>
                                        )
                                    } */}

                                    <div className="reply-body">
                                        {reply && reply?.body &&
                                            <p className="ant-card-meta-title subject" dangerouslySetInnerHTML={{ __html: marked.parse(reply.body) }}></p>
                                        }
                                    </div>

                                </Card>
                            )}
                        </div>
                    </Layout>
                </div>
            </main>
        )
    )
}

export default ViewTicket
