import React from "react";

import { Button, Collapse } from "antd";
import { ChromeOutlined, PlusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const SupportPin = () => {
    return (
        <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
        <Panel header="Support Pin" key="1" icon={<ChromeOutlined />}>
          {/* <p>{text}</p> */}
          <h1 style={{textAlign:"center"}}>59915</h1>
          <Button type="primary" block icon={<PlusOutlined />}> Generate New Pin</Button>
        </Panel>
      </Collapse>
    )
}

export default SupportPin
