import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_EMPLOYEE,
  CREATE_EMPLOYEE,
  GET_EMPLOYEE_DETAILS,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  DELETE_SERVICE
} from "../../constants/ActionTypes";
import axios from 'axios';
import {CisUI} from "../../util/CISUI";
import Config from '../../util/config';


export const getAllEmployee = () => {
  return (dispatch) => {

    dispatch({type: FETCH_START});
    axios.get(Config.apiserver +'employee/employeelist',CisUI().HeaderRequest)
      .then(res => {
      if (res.data.status === 1) {
        // console.log(res.data.data);
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_EMPLOYEE, payload: res.data.data});
      } else {
        dispatch({type: FETCH_ERROR, payload: res.data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_SUCCESS});
      console.log("Error****:", error.message);
    });
  }
};

export const getEmployee = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get(Config.apiserver +'employee/employeeedit/'+id,CisUI().HeaderRequest)
      .then(res => {
        if (res.data.status === 1) {
          // console.log(res.data.data);
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_EMPLOYEE_DETAILS, payload: res.data.data});
        } else {
          dispatch({type: FETCH_ERROR, payload: res.data.msg});
        }
      }).catch(function (error) {
      dispatch({type: FETCH_ERROR});
      console.log("Error****:", error.message);
    });
  }
};

export const DeleteEmployee = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.delete(Config.apiserver + 'employee/employeedelete/' + id, CisUI().HeaderRequest)
      .then(res => {
        if (res.data.status === 1) {
          console.log(res.data.data);
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_EMPLOYEE, payload: {id}});
          CisUI().Notification('success', res.data.msg)
        } else {
          dispatch({type: FETCH_ERROR, payload: res.data.msg});
          CisUI().Notification('error', res.data.msg)
        }
      }).catch(function (error) {
      dispatch({type: FETCH_ERROR});
      console.log("Error****:", error.message);
    });
  }
};

