import React  from "react";
import { Breadcrumb, Row } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import HomeCard from "../../components/HomePage/HomeCard";
const MyService = () => {
	return (
		<>
			<main className="ant-layout-content gx-layout-content gx-container-wrap ">
				<div className="home-main-content">
					<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
						<div className="gx-algolia-main">
							<h1 className="home-top-header">My Service</h1>
							<Breadcrumb className="home-top-breadcrumb">
								<Breadcrumb.Item>
									<Link to="/">Portal Home</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>My Service</Breadcrumb.Item>
							</Breadcrumb>
							<Content className="gx-algolia-content">
								<div className="gx-algolia-content-inner">
									<Row style={{ rowGap: "15px" }} justify="space-between">
										<HomeCard/>
									</Row>
								</div>
							</Content>
						</div>
					</Layout>
				</div>
			</main>
		</>
	);
};

export default MyService;
