module.exports = {
  footerText: 'Copyright Creative IT Soft © 2021',

  // baseurl: 'http://localhost:3001/',
  // apiserver: 'http://localhost:8001/api/',

  // baseurl: 'http://ciscustomercare.yottaerp.com',
  // apiserver: 'https://ciscustomercareapi.yottaerp.com/api/',

  // baseurl: 'https://support.creativeitsoft.net',
  baseurl: 'https://support.yottaerp.com',
  // apiserver: 'https://supportapi.creativeitsoft.net/api/',
  apiserver: 'https://supportapi.yottaerp.com/api',

  profile_img: 'https://supportapi.creativeitsoft.net/public/upload/profile_image/',
  ticket_file: 'https://supportapi.creativeitsoft.net/public/upload/support/',
  cislogo: 'https://support.creativeitsoft.net/public/assets/images/icon.png',
  // cislogo: 'http://localhost:3000/public/assets/images/icon.png',
  // ticket_file: 'http://localhost:8000/upload/support/',


  logourl : '/assets/images/coplogo.png',
  print_logourl : '/assets/images/cop_nlogo.png',
  company_name : 'Creative IT Soft',
  address : '75(Beside BRB Hospital, Panthpath), East Raja Bazar, Dhaka, Bangladesh.',
  city : 'Dhaka',
  zipcode : '1206',
  phone : "+8801670228961",
  email : "info@creativeitsoft.net",
  website : "https://creativeitsoft.net"
}
