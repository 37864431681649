import React from "react";
import { Route, Switch } from "react-router-dom";
import ChangePassword from "../../../containers/Settings/ChangePassword";
import ProfileSettings from "../../../containers/Settings/ProfileSettings";
import SoftwareSettings from "../../../containers/Settings/SoftwareSettings";

const Settings = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}/software_settings`}
        component={SoftwareSettings}
      />
      <Route
        path={`${match.url}/profile_settings`}
        component={ProfileSettings}
      />
         <Route
        path={`${match.url}/change_password`}
        component={ChangePassword}
      />
    </Switch>
  </div>
);

export default Settings;
