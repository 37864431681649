import React, { useEffect, useState } from "react";
import { Breadcrumb, Input, Layout, Card, Menu, Button, Dropdown, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { CisUI } from "../../util/CISUI";
import { EllipsisOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { DeleteService, getAllService } from "../../appRedux/actions/Service";


const adminColumns = [
	{ title: "SL", dataIndex: "sl", key: "sl" },
	{ title: "Product/Service", dataIndex: "service", key: "service" },
	{ title: "Pricing", dataIndex: "pricing", key: "pricing" },
	{ title: "Next Due Date", dataIndex: "duedate", key: "duedate" },
	{ title: "Status", dataIndex: "status", key: "status" },
	{ title: "Actions", dataIndex: "actions", key: "actions" },
];

const customerColumns = [
	{ title: "SL", dataIndex: "sl", key: "sl" },
	{ title: "Product/Service", dataIndex: "service", key: "service" },
	{ title: "Pricing", dataIndex: "pricing", key: "pricing" },
	{ title: "Next Due Date", dataIndex: "duedate", key: "duedate" }
];

const ServiceList = () => {
	const [tableData, setTableData] = useState([]);
	// const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	let services = useSelector(({ service }) => service.services);
	const userData = JSON.parse(CisUI().getLocalUserData());
	let userArea = userData['area'];
	const publishDate = (orgianlDate) => {
		var date = new Date(orgianlDate);
		date = date.toDateString();
		return date;
	}
	const handleSearch = ((e) => {
		console.log(e.target.value)

		const salesproduct = [];

		services.map((service, i) => {
			if (service.name.toLowerCase().includes(e.target.value.toLowerCase())) {
				salesproduct.push({
					key: service.id,
					sl: ++i,
					service: service.name,
					pricing: service.price,
					duedate: publishDate(service.created_at),
					status: CisUI().getStatusLabel(service.status),
					actions: (
						<Dropdown overlay={actionMenu(service.id)} placement="bottomCenter">
							<Button className="list-table-btn" size="small">
								<EllipsisOutlined />
							</Button>
						</Dropdown>
					),
				});
			}
		})
		setTableData(salesproduct)
	});

	useEffect(() => {
		dispatch(getAllService());
	}, []);


	const getTableData = () => {
		const data = [];

		services.map((service, i) => {
			data.push({
				key: service.id,
				sl: ++i,
				service: service.name,
				pricing: service.price,
				duedate: publishDate(service.created_at),
				status: CisUI().getStatusLabel(service.status),
				actions: (
					<Dropdown overlay={actionMenu(service.id)} placement="bottomCenter">
						<Button className="list-table-btn" size="small">
							<EllipsisOutlined />
						</Button>
					</Dropdown>
				),
			});
		});
		setTableData(data)
	}

	useEffect(() => {
		getTableData();
	}, [services]);

	const actionMenu = (id) => {
		const menu = (
			<Menu>
				<Menu.Item key={`edit_` + id}>
					<Link to={`/admin/service/editservice/${id}`}>
						<Button size="small" type="primary" className="list-table-btn" block >
							Edit
						</Button>
					</Link>
				</Menu.Item>
				<Menu.Item key={`delete_` + id}>
					<Button size="small" type="primary" className="list-table-btn" block onClick={() => { showConfirm(id) }}>
						Delete
					</Button>
				</Menu.Item>
			</Menu>
		);
		return menu;
	};

	function showConfirm(serviceID) {
		Modal.confirm({
			title: "Do you Want to delete these items?",
			content: "Some descriptions",
			okText: "Ok",
			cancelText: "Cancel",
			onOk() {
				dispatch(DeleteService(serviceID))
			},
			onCancel() {
				console.log("Cancel");
			},
		});
		console.log(serviceID);
	}


	return (
		// loading ? <Loading /> :
			<main className="ant-layout-content gx-layout-content gx-container-wrap ">
				<div className="home-main-content">
					<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
						<div className="gx-algolia-main">
							<h1 className="home-top-header">My Services<Link to="./addservice"><Button className="title-btn" icon={<PlusOutlined />} /></Link></h1>
							<Breadcrumb className="home-top-breadcrumb">
								<Breadcrumb.Item>
									<Link to="/">Portal Home</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>My Services</Breadcrumb.Item>
							</Breadcrumb>
							<Content className="gx-algolia-content">
								<div className="gx-algolia-content-inner">
									<Card className="tickets-card">
										<Card
											type="inner"

											extra={
												<Input onChange={handleSearch} size="large" placeholder="Search Service" addonAfter={<SearchOutlined />} />
											}
										>
											<Table scroll={{ x: true }} columns={userArea === 1 ? adminColumns : customerColumns} dataSource={tableData} />
										</Card>
									</Card>
								</div>
							</Content>
						</div>
					</Layout>
				</div>
			</main>
	);
};

export default ServiceList;
