import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Country from "./Country";
import Employee from "./Employee";
import Service from "./Service";
import Customer from "./Customer";


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  country: Country,
  customer: Customer,
  employee: Employee,
  service: Service,
});

export default createRootReducer
