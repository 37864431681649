import React from "react";
import { Route, Switch } from "react-router-dom";
import AddCustomer from "../../../containers/Customer/AddCustomer";
import CustomerList from "../../../containers/Customer/CustomerList";
import EditCustomer from "../../../containers/Customer/EditCustomer";
// import DeleteCustomer from "../../containers/Service/DeleteCustomer";

const Customer = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/addcustomer`} component={AddCustomer}/>
      <Route path={`${match.url}/customerlist`} component={CustomerList}/>
      <Route path={`${match.url}/editcustomer/:id`} component={EditCustomer}/>
      {/* <Route path={`${match.url}/customerdelete/:id`} component={DeleteCustomer}/> */}
    </Switch>
  </div>
);

export default Customer;
