import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../../../containers/Dashboard";

const Dashboard = ({match}) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}/`} component={Home} />
      </Switch>
    </div>
  );
};

export default Dashboard;
