import React from "react";
import { Route, Switch } from "react-router-dom";
import AddInvoice from "../../../containers/Billing/AddInvoice";
import EditInvoice from "../../../containers/Billing/EditInvoice";
import Invoices from "../../../containers/Billing/Invoices";

const Billing = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/addinvoice`} component={AddInvoice}/>
      <Route path={`${match.url}/invoicelist`} component={Invoices}/>
      <Route path={`${match.url}/editinvoice/:id`} component={EditInvoice}/>
      {/* <Route path={`${match.url}/customerdelete/:id`} component={DeleteCustomer}/> */}
    </Switch>
  </div>
);

export default Billing;
