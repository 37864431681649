import React, { useEffect, useState } from "react";

import { Button, Collapse } from "antd";
import { ChromeOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../Loading";

const { Panel } = Collapse;

const YourInfo = () => {
  const getuserdata = CisUI().getLocalUserData();
  const profileID = JSON.parse(getuserdata).id;
  const history = useHistory();
  const [profile, setProfile] = useState([]);
  const [countries, setCountries] = useState([]);

  const getProfileData = () => {
    axios
      .get(
        config.apiserver + `settings/showprofile/${profileID}`,
        CisUI().HeaderRequest
      )
      .then((res) => {
        if (res.data.status == 1) {
          setProfile(res.data.data);
          console.log(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((errors) => console.log(errors));
  };
  const getCountries = () => {
    axios
      .get(config.apiserver + "country/countrylist", CisUI().HeaderRequest)
      .then((res) => {
        if (res.data.status == 1) {
          setCountries(res.data.data);
          console.log(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((errors) => console.log(errors));
  };

  useEffect(() => {
    getProfileData();
    getCountries();
  }, []);

  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition={"right"}
      style={{ marginTop: "10px" }}
    >
      <Panel header="Your Info" key="1" icon={<ChromeOutlined />}>
        {profile == "" ? (
          "Lading..."
        ) : (
          <>
            <h1 style={{ textAlign: "center" }}>{profile.name}</h1>
            <p>
            {profile.name} <br /> {profile.address} <br /> North Adabor Dhaka,{" "}
              <br /> {profile.city}
              
         


              <br />
              Dhaka,
              <br /> 1207 Bangladesh
            </p>
          </>
        )}

        <Link
          className="ant-btn ant-btn-primary ant-btn-block"
          to="/settings/profile_settings"
        >
          Update
        </Link>
      </Panel>
    </Collapse>
  );
};

export default YourInfo;
