import React, { useState, useEffect } from "react";
import { Breadcrumb, Input, Layout, Card, Button, Table, Menu, Dropdown, Modal, } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";


import { CisUI } from "../../util/CISUI";
import { EllipsisOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { DeleteEmployee, getAllEmployee } from "../../appRedux/actions/Employee";

const columns = [
	{
		title: "SL",
		dataIndex: "sl",
		key: "sl",
	},
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
	},
	{
		title: "Phone",
		dataIndex: "phone",
		key: "phone",
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
	},
	{
		title: "Actions",
		dataIndex: "action",
		key: "action",
	},
];

const EmployeeList = () => {
	const [tableData, setTableData] = useState([]);

	const dispatch = useDispatch();
	let employees = useSelector((state) => state.employee.employees)

	const getTableData = () => {
		const data = [];
		employees.map((employee, i) => {
			data.push({
				key: employee.id,
				sl: ++i,
				name: employee.name,
				email: employee.email,
				phone: employee.phone,
				status: CisUI().getStatusLabel(employee.status),
				action: (
					<Dropdown overlay={actionMenu(employee.id)} placement="bottomCenter">
						<Button className="list-table-btn" size="small">
							<EllipsisOutlined />
						</Button>
					</Dropdown>
				),
			});
		});
		setTableData(data)
	}

	useEffect(() => {
		getTableData();
	}, [employees]);


	const handleSearch = ((e) => {
		console.log(e.target.value)
		const salesproduct = [];

		employees.map((employee, i) => {
			if (employee.name.toLowerCase().includes(e.target.value.toLowerCase()) || employee.email.toLowerCase().includes(e.target.value.toLowerCase()) || employee.phone.toLowerCase().includes(e.target.value.toLowerCase())) {
				salesproduct.push({
					key: employee.id,
					sl: ++i,
					name: employee.name,
					email: employee.email,
					phone: employee.phone,
					status: CisUI().getStatusLabel(employee.status),
					action: (
						<Dropdown overlay={actionMenu(employee.id)} placement="bottomCenter">
							<Button className="list-table-btn" size="small">
								<EllipsisOutlined />
							</Button>
						</Dropdown>
					),
				});
			}
		})
		setTableData(salesproduct)
	});


	const actionMenu = (id) => {
		const menu = (
			<Menu>
				<Menu.Item>
					<Link to={`/admin/employee/editemployee/${id}`}>
						<Button
							size="small"
							type="primary"
							className="list-table-btn"
							block
						>
							Edit
						</Button>
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Button
						size="small"
						type="primary"
						className="list-table-btn"
						block
						onClick={() => {
							showConfirm(id);
						}}
					>
						Delete
					</Button>
				</Menu.Item>
			</Menu>
		);

		return menu;
	};

	function showConfirm(employeeID) {
		Modal.confirm({
			title: "Do you Want to delete these items?",
			content: "Some descriptions",
			okText: "Ok",
			cancelText: "Cancel",
			onOk() {
				dispatch(DeleteEmployee(employeeID));
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	}

	useEffect(() => {
		dispatch(getAllEmployee());
	}, []);

	return (
		<main className="ant-layout-content gx-layout-content gx-container-wrap ">
			<div className="home-main-content">
				<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
					<div className="gx-algolia-main">
						<h1 className="home-top-header">Employee
							<Link to="./addemployee"><Button className="title-btn" icon={<PlusOutlined />} /></Link>
						</h1>
						<Breadcrumb className="home-top-breadcrumb">
							<Breadcrumb.Item>
								<Link to="/">Portal Home</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item>Employee</Breadcrumb.Item>
						</Breadcrumb>
						<Content className="gx-algolia-content">
							<div className="gx-algolia-content-inner">
								<Card className="tickets-card">
									<Card
										type="inner"
										title="Showing 1 to 1 of 1 entries"
										extra={
											<Input onChange={handleSearch} size="large" placeholder="Search Ticket" addonAfter={<SearchOutlined />} />
										}
									>
										<Table scroll={{ x: true }} columns={columns} dataSource={tableData} />
									</Card>
								</Card>
							</div>
						</Content>
					</div>
				</Layout>
			</div>
		</main>
	);
};

export default EmployeeList;
