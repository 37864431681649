import React from "react";
import { Route, Switch } from "react-router-dom";
import Admin from "./admin";
import Customer from "./customer";
import { useSelector } from "react-redux";


const App = ({ match }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Switch>
      {authUser.area == 1 ? <Route path={`${match.url}admin`} component={Admin} /> : <Route path={`${match.url}customer`} component={Customer} />}
    </Switch>
  )
};

export default App;
