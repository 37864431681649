
import { Menu } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import React from 'react';

import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

const { SubMenu } = Menu;

// class HomeSideNav extends React.Component {
//   handleClick = e => {
//     console.log('click ', e);
//   };

//   render() {
 
//     return (
   
//     );
//   }
// }


// export default HomeSideNav



// import React from 'react'

const HomeSideNav = () => {

  const dispatch = useDispatch();
  return (
    <Menu
    // onClick={this.handleClick}
    defaultOpenKeys={['shortcuts']}
    mode="inline"
  >
    <SubMenu key="shortcuts" title="Shortcuts" className="shortcuts-homesubmenu">
      <Menu.Item key="1">Order New Services</Menu.Item>
      <Menu.Item key="2">Register New Domain</Menu.Item>
      <Menu.Item key="3" onClick={() => dispatch(userSignOut())}>Logout</Menu.Item>
    </SubMenu>
  </Menu>
  )
}

export default HomeSideNav

