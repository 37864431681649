import React, {useEffect} from "react";
import {Button, Checkbox, Row, Col, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {
    hideMessage,
    userSignIn,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import InfoView from "../components/InfoView";

const SignIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authUser = useSelector(({auth}) => auth.authUser);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    const onFinish = values => {
        dispatch(userSignIn(values));
    };

    useEffect(() => {
        if (authUser !== null) {
            history.push('/');
        }
    }, [authUser]);


    return (
        <Row>
            {/*<Col span={16} className="h-100">*/}
            {/*    <div className="gx-app-login-wrap">*/}

            {/*        <div className="gx-app-login-container-left">*/}

            {/*            <div className="gx-app-logo">*/}
            {/*                /!*<img alt="example" src="/assets/images/bg/img-home.jpeg"/>*!/*/}
            {/*                /!*<div className="bg-login">*!/*/}
            {/*                /!*    <h1>Test</h1>*!/*/}
            {/*                /!*</div>*!/*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Col>*/}
            <Col span={8} className="h-100">
                <div className="gx-app-login-wrap">

                    <div className="gx-app-login-main-content">

                        <div className="gx-app-logo-content">
                            <div className="gx-app-logo-content-bg">
                                <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
                            </div>
                            <div className="gx-app-logo-wid">
                                {/*<h1 className="color-black"><IntlMessages id="app.userAuth.signIn"/></h1>*/}
                                {/*<p className="color-black" ><IntlMessages id="app.userAuth.bySigning"/></p>*/}
                                {/*<p className="color-black" > <IntlMessages id="app.userAuth.getAccount"/></p>*/}
                                <div className="gx-app-logo" style={{marginTop: "34px"}}>
                                    <img alt="example" src="/assets/images/cis_logo1.svg"/>
                                </div>
                            </div>
                        </div>

                        <div className="gx-app-login-content">
                            <Form
                                initialValues={{remember: true}}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                className="gx-signin-form gx-form-row0">
                                <Col span={24}>
                                    <Form.Item
                                        rules={[{required: true, message: 'The input is not valid E-mail!'}]}
                                        name="email">
                                        <Input placeholder="User Name"/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        rules={[{required: true, message: 'Please input your Password!'}]}
                                        name="password">
                                        <Input type="password" placeholder="Password"/>
                                    </Form.Item>
                                </Col>
                                <Col span={6} offset={17}>
                                    <Form.Item>
                                        <Button type="primary" className="gx-mb-0" htmlType="submit">
                                            <IntlMessages id="app.userAuth.signIn"/>
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Form>
                        </div>
                        <InfoView/>
                    </div>
                </div>
            </Col>
        </Row>
    )
        ;
};

export default SignIn;
