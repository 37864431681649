import React, { useState } from "react";
import { Breadcrumb, Col, Input, Layout, Row, Select, Form, notification, Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addService } from "../../appRedux/actions/Service";

import { MenuOutlined } from '@ant-design/icons'
import Loading from "../../components/Loading";

const { Option } = Select;
const { TextArea } = Input;

const AddService = () => {
	// const [loading, setLoading] = useState(false)
	const history = useHistory();
	const dispatch = useDispatch();
	const { status } = useSelector(({ service }) => service);
	const onFinish = (values) => {
		dispatch(addService(values))
	};

	if (status === 1) {
		history.push("/admin/service/servicelist");
	}
	return (
		// loading ? <Loading /> :
			<main className="ant-layout-content gx-layout-content gx-container-wrap ">
				<div className="home-main-content">
					<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
						<div className="gx-algolia-main">
							<h1 className="home-top-header">Add Service<Link to="./servicelist"><Button className="title-btn" icon={<MenuOutlined />} /></Link></h1>

							<Breadcrumb className="home-top-breadcrumb">
								<Breadcrumb.Item>
									<Link to="/">Portal Home</Link>
								</Breadcrumb.Item>

								<Breadcrumb.Item>Add Service</Breadcrumb.Item>
							</Breadcrumb>
							<Content className="gx-algolia-content">
								<div className="gx-algolia-content-inner">
									<Form onFinish={onFinish}>
										<Row
											className="ant-row"
											style={{ rowGap: "15px" }}
											justify="space-between"
										>
											<Col lg={12} xs={24}>
												<Form.Item
													label="Name"
													rules={[
														{ required: true, message: "Name is required" },
													]}
													name="name"
													className="form-lblock"
												>
													<Input placeholder="Name" />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Service Type"
													rules={[
														{
															required: true,
															message: "Service Type is required",
														},
													]}
													name="type"
													className="form-lblock"
												>
													<Select
														placeholder="Select An Option"
														style={{ width: "100%" }}
														name="type"
													>
														<Option value="daily">Daily</Option>
														<Option value="monthly">Monthly</Option>
														<Option value="halfyearly">Half Yearly</Option>
														<Option value="yearly">Yearly</Option>
													</Select>
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Price"
													rules={[
														{ required: true, message: "Price is required" },
													]}
													name="price"
													className="form-lblock"
												>
													<Input placeholder="Price" />
												</Form.Item>
											</Col>

											<Col lg={12} xs={24}>
												<Form.Item
													label="Service Status"
													rules={[
														{
															required: true,
															message: "Service Status is required",
														},
													]}
													name="status"
													className="form-lblock"
												>
													<Select
														placeholder="Select An Option"
														style={{ width: "100%" }}
														name="status"
													>
														<Option value="1">Active</Option>
														<Option value="0">Inactive</Option>
													</Select>
												</Form.Item>
											</Col>

											<Col lg={24} xs={24}>
												<Form.Item
													label="Service Details"
													rules={[
														{
															required: true,
															message: "Service Details is required",
														},
													]}
													name="details"
													className="form-lblock"
												>
													<TextArea rows={5} />
												</Form.Item>
											</Col>

											<Col lg={24} xs={24} style={{ textAlign: "center" }}>
												<Button type="secondary" htmlType="reset">
													Reset
												</Button>
												<Button type="primary" htmlType="submit">
													Save
												</Button>
											</Col>
										</Row>
									</Form>
								</div>
							</Content>
						</div>
					</Layout>
				</div>
			</main>
	);
};

export default AddService;
