import React from "react";
import { Route, Switch } from "react-router-dom";

import AddEmployee from "../../../containers/Employee/AddEmployee";
import EditEmployee from "../../../containers/Employee/EditEmployee";
import EmployeeList from "../../../containers/Employee/EmployeeList";

const Employee = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/addemployee`} component={AddEmployee} />
      <Route path={`${match.url}/employeelist`} component={EmployeeList} />
      <Route path={`${match.url}/editemployee/:id`} component={EditEmployee} />
    </Switch>
  </div>
);

export default Employee;
